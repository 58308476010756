import { HeadFC } from 'gatsby';
import React from 'react';

const pageStyles = {
    color: '#232129',
    padding: '96px',
    fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
};

const paragraphStyles = {
    marginBottom: 48,
};

const aLinkStyles = {
    color: '#0b0ff3',
};

const NotFoundPage = () => {
    return (
        <main style={pageStyles}>
            <h1 style={headingStyles}>Page not found</h1>
            <p style={paragraphStyles}>
                Sorry 😔, we couldn’t find what you were looking for.
                <br />
            </p>
            <p>
                Are you lost?{' '}
                <a href='https://www.kinali.cz/cs/' style={aLinkStyles}>
                    Click here.
                </a>
            </p>
        </main>
    );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
